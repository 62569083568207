<template>
  <div class="m-5">
    <DataTable
      showGridlines
      :value="plans"
      :lazy="true"
      :paginator="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="plan_name"
      :rowHover="true"
      @page="onPage($event)"
      @sort="onSort($event)"      
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Exercise Plan</div>
          <div class="col-6 text-right list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoExercisePlan"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="list-search"
              />
            </div>
          </div>
        </div>
      </template>      
      <template #empty> No records found. </template>
      <Column field="planName" header="Plan Name" :sortable="true"></Column>
      <Column
        field="expe_Workout"
        header="Experience For Workout"
        :sortable="true"
      ></Column>
      <Column field="created_by" header="Created By" :sortable="true"></Column>
      <Column field="created_at" header="Created At" :sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoExercisePlanEdit(data.data.id)"
            />
            <PrimeButton
              label="Remove"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteExercisePlan"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import ExercisePlanFactory from "@/factories/exercise-plan";

export default {
  data() {
    return {
      plans: [],
      displayBasic: false,
      planName: [],
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",      
      showPopup: false,
      exercisePlanIdForDelete: "",
    };
  },
  mounted() {
    this.getExercisePlanList();
  },
  methods: {
    gotoExercisePlan() {
      this.$router.push({ name: "ExercisePlanCreate" });
    },
    gotoExercisePlanEdit(exercisePlanId) {
      this.$router.push({
        name: "ExercisePlanEdit",
        params: { id: exercisePlanId },
      });
    },
    showDeletePopup(exercisePlanId) {
      this.exercisePlanIdForDelete = exercisePlanId;
      this.showPopup = true;
    },
    deleteExercisePlan() {
      APIService.delete(`/exercise_plan/delete/${this.exercisePlanIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getExercisePlanList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getExercisePlanList() {      
      this.plans = [];
      APIService.get(
        `/exercise_plan/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.plans = ExercisePlanFactory.createFromJsonArray(
              response.data.data
            );
          }
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getExercisePlanList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "planName") {
        sortField = "planName";
      } else if (sortData.sortField === "expe_Workout") {
        sortField = "expe_Workout";
      } else if (sortData.sortField === "created_by") {
        sortField = "createdBy";
      } else if (sortData.sortField === "created_at") {
        sortField = "createdAt";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getExercisePlanList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getExercisePlanList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>