<template>
  <div :class="divClass">Faith & Health Rehabilitation Service Company</div>
</template>

<script>
export default {
  props: {
    divClass: {
      type: String,
      default: "footer-text",
    },
  },
};
</script>