import DateFormat from "@/libs/DateFormat"

export default class DropdownMaster {
    constructor(data = {}) {
        return {
            id: data.id || '',
            name: data.name || '',
            created_by: data.createdBy || '',
            created_at: DateFormat.formatDate(data.createdAt) || '',
        }
    }
}
