<template>
  <SidebarMenu />
  <div v-show="showloader" class="progress-section">
    <ProgressSpinner strokeWidth="3" />
  </div>
  <div style="margin-left: 360px">
    <router-view></router-view>
  </div>
  <PrimeToast />
</template>

<script>
import SidebarMenu from "@/components/layout/sidebar-menu.vue";

export default {
  components: {
    SidebarMenu,
  },
  computed: {
    showloader() {
      return this.$store.state.showLoader;
    },
  },
};
</script>

<style>
.progress-section {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
}

.p-progress-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>