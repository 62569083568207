import DateFormat from "@/libs/DateFormat"

export default class Insight {
    constructor(data = {}) {
        return {
            id: data.id || '',
            title: data.title || '',
            content: data.content || '',
            created_by: data.createdBy || '',
            created_at: DateFormat.formatDate(data.createdAt) || '',
        }
    }
}
