import TutorialVideo from "@/models/tutorial-video";

export default class TutorialVideoFactory {
    static createFromJson(json) {
        return new TutorialVideo(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(TutorialVideoFactory.createFromJson(item))
        })

        return jsonData
    }
}
