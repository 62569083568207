/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import App from './App.vue'
import MainFooter from '@/components/layout/main-footer.vue'
import router from './router'
import store from './store';

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import ConfirmationService from 'primevue/confirmationservice';
import ProgressSpinner from 'primevue/progressspinner';
import InputGroup from '@/components/form-control/input-group.vue'
import InputTextRequire from '@/components/form-control/input-text.vue';
import TextAreaRequire from '@/components/form-control/text-area.vue';
import DropDownRequire from '@/components/form-control/drop-down.vue';
import ConfirmPopup from '@/components/modal-popup/confirm-popup.vue';
import moment from 'moment-timezone'
import Editor from 'primevue/editor';
import EditorRequire from "@/components/form-control/editor.vue"

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeflex/primeflex.min.css'
import 'primeicons/primeicons.css'                           //icons
import '@/assets/css/global.css'

const app = createApp(App)
app.use(store)
app.use(PrimeVue)
app.use(ToastService)
app.use(ConfirmationService)
app.use(moment);

app.component('InputText', InputText)
app.component('InputGroup', InputGroup)
app.component('InputTextRequire', InputTextRequire)
app.component('TextAreaRequire', TextAreaRequire)
app.component('PrimeButton', Button)
app.component('PrimeToast', Toast)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Dropdown', Dropdown)
app.component('DropDownRequire', DropDownRequire)
app.component('Textarea', Textarea)
app.component('FileUpload', FileUpload)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ConfirmPopup', ConfirmPopup)
app.component('MainFooter', MainFooter)
app.component('Editor', Editor)
app.component('EditorRequire', EditorRequire)

app.use(router).mount('#app')
