<template>
  <div class="sidebar">
    <div class="sb-title">
      <span class="sb-title-text"><img src="../../assets/images/logo.svg" alt="" class="logo" /></span>
    </div>

    <div class="menu">
      <router-link
        :to="{ name: 'MemberAccount' }"
        id="MemberAccount"
        :class="
          $store.state.activeRoute === 'MemberAccount'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="member-icon"></i>
        <span class="menu-text">Member Account</span>
      </router-link>

      <router-link
        :to="{ name: 'ExercisePlan' }"
        id="ExercisePlan"
        :class="
          $store.state.activeRoute === 'ExercisePlan'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="plan-icon"></i>
        <span class="menu-text">Exercise Plan</span>
      </router-link>

      <router-link
        :to="{ name: 'WorkoutExercise' }"
        id="WorkoutExercise"
        :class="
          $store.state.activeRoute === 'WorkoutExercise'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="exercises-icon"></i>
        <span class="menu-text">Workout Exercise</span>
      </router-link>

      <router-link
        :to="{ name: 'TutorialVideo' }"
        id="TutorialVideo"
        :class="
          $store.state.activeRoute === 'TutorialVideo'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="video-icon"></i>
        <span class="menu-text">Tutorial & Video</span>
      </router-link>

      <router-link
        :to="{ name: 'Insights' }"
        id="Insights"
        :class="
          $store.state.activeRoute === 'Insights'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="insights-icon"></i>
        <span class="menu-text">Insights</span>
      </router-link>
      <!-- <div
        class="menu-item cursor-pointer"
        @click="visibleCMSMenu = !visibleCMSMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i class="chevron-down-icon"></i>
      </div> -->
      <div
        class="menu-item cursor-pointer"
        @click="visibleCMSMenu = !visibleCMSMenu"
      >
        <!-- <i class="dropdown-icon"></i> -->
        <span class="menu-text">CMS Page Management</span>
        <i class="chevron-down-icon"></i>
      </div>
      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'PrivacyPolicy' }"
        id="PrivacyPolicy"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text">Privacy Policy Page</span>
      </router-link>

      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'TermsAndConditions' }"
        id="TermsAndConditions"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text">Terms & Conditions Page</span>
      </router-link>
      <div
        class="menu-item cursor-pointer"
        @click="visibleDDMenu = !visibleDDMenu"
      >
        <i class="dropdown-icon"></i>
        <span class="menu-text">Dropdown List</span>
        <i class="chevron-down-icon"></i>
      </div>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'WorkoutExperiance' }"
        id="WorkoutExperiance"
        :class="
          $store.state.activeRoute === 'WorkoutExperiance'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text">Experience for workout</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'TargetMuscle' }"
        id="TargetMuscle"
        :class="
          $store.state.activeRoute === 'TargetMuscle'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text">Target Muscle</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'IdealBodyShape' }"
        id="IdealBodyShape"
        :class="
          $store.state.activeRoute === 'IdealBodyShape'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text">Ideal Body Shape</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'Difficulty' }"
        id="Difficulty"
        :class="
          $store.state.activeRoute === 'Difficulty'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text">Difficulty</span>
      </router-link>
    </div>
    <div class="menu-footer">
      <div class="user-info">
        <i class="avatar-icon"></i>
        <span class="user-info-text">{{ $store.state.userInfo.username }}</span>
      </div>

      <button class="btn-logout cursor-pointer" @click="logout">
        <span class="btn-logout-text">Logout</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  data() {
    return {
      visibleLeft: true,
      visibleDDMenu: false,
      visibleCMSMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/sidebar.css";
</style>
