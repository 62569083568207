import DateFormat from "@/libs/DateFormat"

export default class WorkoutExercise {
    constructor(data = {}) {
        return {
            id: data.id || '',
            workoutname: data.workoutname || '',
            target_muscle: data.target_muscle || '',
            difficulty: data.difficulties || '',
            created_by: data.createdBy || '',
            created_at: DateFormat.formatDate(data.createdAt) || '',
        }
    }
}
