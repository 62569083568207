import WorkoutExercise from '@/models/workout-exercise';

export default class WorkoutExerciseFactory {
    static createFromJson(json) {
        return new WorkoutExercise(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(WorkoutExerciseFactory.createFromJson(item))
        })

        return jsonData
    }
}