<template>
  <div class="main-container">
    <div class="login-form">
      <div class="login-title">
        <img src="../assets/images/logo.svg" alt="" class="logo" />
      </div>
      <div class="form-container">
        <div class="flex">
          <span
            class="chevron-left-icon cursor-pointer"
            @click="gotoLogin"
          ></span>
          <div
            class="
              form-title
              w-full
              justify-content-center
              align-content-center
            "
          >
            Forgot password
          </div>
        </div>
        <div class="form-inputs">
          <InputTextRequire
            v-model="vmodel.emailAddress"
            :validation="v$.vmodel.emailAddress"
            sectionClass="field mt-1"
            id="email"
            type="email"
            label="Email"
            maxlength="50"
          />
          <div class="field mt-6 mb-2">
            <PrimeButton
              label="Continue"
              class="prime-button"
              @click="submitForm"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      Faith & Health Rehabilitation Service Company
    </div>
    <PrimeToast />
  </div>
</template>
  
<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, email, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      vmodel: {
        emailAddress: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        emailAddress: { required, email, max: maxLength(50) },
      },
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push({ name: "Login" });
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.submitted = true;
        APIService.get(`/admin/forgetPassword/${this.vmodel.emailAddress}`)
          .then((response) => {
            if (response && response.data) {
              this.$toast.add({
                severity: "success",
                summary: "Mail Send",
                detail: "Please check your email to proceed further.",
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "Login" });
              }, 3000);
            } else {
              this.$toast.add({
                severity: "error",
                summary: "User Not Found",
                detail:
                  "You are not registered. Please contact to administrator.",
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>
  
<style scoped>
@import "@/assets/css/login.css";
</style>