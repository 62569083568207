import DateFormat from "@/libs/DateFormat"

export default class Member {
    constructor(data = {}) {
        return {
            id: data.id || '',
            username: data.username || '',
            email: data.emailId || '',
            password: data.password || '',
            created_by: data.createdBy || '',
            created_at: DateFormat.formatDate(data.createdAt) || '',
        }
    }
}
