<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 faith-form-title">{{ title }}</div>
      <div class="faith-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="faith-form-sub-title">Insights Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.title"
                :validation="v$.vmodel.title"
                sectionClass="field mt-1"
                id="title"
                label="Title"
                maxlength="50"
              />
              <div class="field">
                <label for="photo" class="form-label">Photo</label>
                <div
                  class="photo-section cursor-pointer"
                  @click="openFileUpload"
                >
                  <span class="upload-icon"></span>
                  <div class="upload-text">Upload a file</div>
                  <div class="upload-text-small">Maximum file size: 1 MB</div>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept="image/png, image/jpg, image/jpeg"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
                <div
                  v-if="
                    v$.vmodel.images.$error &&
                    v$.vmodel.images.required &&
                    v$.vmodel.images.required.$invalid
                  "
                  :id="`photo-help`"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.images.required.$message.replace("Value", "Photo")
                  }}
                </div>
              </div>
              <div class="field">
                <div v-for="(image, index) in imageList" :key="index">
                  <div class="insight-img-main">
                    <img :src="image.image" class="insight-img" />
                    <div class="insight-image-content">
                      <div class="insight-image-name">{{ image.name }}</div>
                      <div class="insight-image-size">
                        {{ parseInt(image.size / 1024) }} KB
                      </div>
                    </div>
                    <div class="insight-image-remove cursor-pointer">
                      <span
                        @click="showDeletePopup(index)"
                        class="delete-icon"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <TextAreaRequire
                v-model="vmodel.content"
                :validation="v$.vmodel.content"
                sectionClass="field mt-1"
                id="content"
                label="Content"
                maxlength="500"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="faith-form-button"
                  v-if="!isEditMode"
                  @click="createInsight"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="faith-form-button"
                  v-else
                  @click="createInsight"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
  <div>
    <ConfirmPopup
      :show-popup="showPopup"
      @confirmYes="deleteImage"
      @confirmNo="showPopup = false"
    ></ConfirmPopup>
  </div>
</template>
    
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import APIService from "@/services/api.service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showPopup: false,
      title: "Add Insights",
      isEditMode: false,
      imageList: [],
      photo: "",
      imageIndex: null,
      vmodel: {
        title: "",
        content: "",
        images: [],
      },
    };
  },
  validations() {
    return {
      vmodel: {
        title: { required, max: maxLength(50) },
        content: { required },
        images: { required },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Insights";
      this.isEditMode = true;
      this.getInsightById();
    }
  },
  methods: {
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/media/images", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              const uploadImgList = {
                id: response.data.data.id,
                image:
                  process.env.VUE_APP_INSIGHT_IMAGE_BASE_PATH +
                  response.data.data.name,
                name: response.data.data.originalname,
                size: response.data.data.size,
              };
              this.imageList.push(uploadImgList);
              this.vmodel.images.push(response.data.data.id);              
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createInsight() {
      this.vmodel.images = this.imageList.map((x) => x.id);
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          title: this.vmodel.title,
          content: this.vmodel.content,
          photoID: this.vmodel.images,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/insight", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Insights" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/insight/", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Insights" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getInsightById() {
      APIService.getById("/insight", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.title = response.data.data.title;
            this.vmodel.content = response.data.data.content;
            this.vmodel.images = response.data.data.photoID;
            if (response.data.images && response.data.images.length > 0) {
              response.data.images.forEach((element) => {
                const uploadImgList = {
                  id: element._id,
                  image:
                    process.env.VUE_APP_INSIGHT_IMAGE_BASE_PATH + element.name,
                  name: element.originalname,
                  size: element.size,
                };
                this.imageList.push(uploadImgList);
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Insights" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    showDeletePopup(index) {
      this.imageIndex = index;
      this.showPopup = true;
    },
    deleteImage() {
      this.imageList.splice(this.imageIndex, 1);
    },
  },
};
</script>

<style>
.photo-section {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 4px;

  height: 136px;

  background: #ffffff;
  border: 1px dashed #bec6cb;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.upload-text {
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #25b1fb;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.upload-text-small {
  height: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #adb1b3;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.insight-img-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  height: 80px;
  margin-bottom: 24px;
  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.insight-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.insight-image-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 44px;

  /* Inside auto layout */
  order: 1;
  flex-grow: 1;
}

.insight-image-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #adb1b3;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.insight-image-size {
  height: 20px;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #adb1b3;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.insight-image-remove {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 4px;

  width: 36px;
  height: 36px;

  background: #f4f5f7;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
</style>