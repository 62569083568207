<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 faith-form-title">{{ title }}</div>
      <div class="faith-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="faith-form-sub-title">Member Account Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.username"
                :validation="v$.vmodel.username"
                sectionClass="field mt-1"
                id="username"
                label="Username"
                maxlength="50"
              />
              <InputTextRequire
                v-model="vmodel.email"
                :validation="v$.vmodel.email"
                sectionClass="field mt-1"
                id="email"
                type="email"
                label="Email"
                maxlength="50"
              />
              <InputTextRequire
                v-model="vmodel.password"
                :validation="v$.vmodel.password"
                sectionClass="field mt-1"
                id="password"
                type="password"
                label="Password"
                minlength="8"
                maxlength="50"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="faith-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="faith-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text absolute" />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, email, maxLength, minLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Add Member Account",
      isEditMode: false,
      vmodel: {
        username: null,
        email: null,
        password: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        username: { required, max: maxLength(50) },
        email: { required, email, max: maxLength(50) },
        password: { required, min: minLength(8), max: maxLength(20) },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Member account";
      this.isEditMode = true;
      this.getMemberById();
    }
  },
  methods: {
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          username: this.vmodel.username,
          emailId: this.vmodel.email,
          password: this.vmodel.password,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/member_account", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberAccount" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/member_account/", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberAccount" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getMemberById() {
      APIService.getById("/member_account", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.emailId;
            this.vmodel.password = response.data.data.password;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberAccount" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>