<template>
  <div class="m-5">
    <DataTable
      showGridlines
      :value="exercises"
      :lazy="true"
      :paginator="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="workoutname"
      :rowHover="true"
      @page="onPage($event)"
      @sort="onSort($event)"      
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Workout Exercise</div>
          <div class="col-6 text-right list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoWorkoutExercise"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="list-search"
              />
            </div>
          </div>
        </div>
      </template>      
      <template #empty> No records found. </template>
      <Column
        field="workoutname"
        header="Exercise Name"
        :sortable="true"
      ></Column>
      <Column
        field="target_muscle"
        header="Target Muscle"
        :sortable="true"
      ></Column>
      <Column field="difficulty" header="Difficulty" :sortable="true"></Column>
      <Column
        field="created_by"
        header="Created By"
        :sortable="true"
        class="col-width"
      ></Column>
      <Column field="created_at" header="Created At" :sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoWorkoutExerciseEdit(data.data.id)"
            />
            <PrimeButton
              label="Remove"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteWorkoutExercise"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import WorkoutExerciseFactory from "@/factories/workout-exercise";

export default {
  data() {
    return {
      displayBasic: false,
      exercises: [],
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",      
      showPopup: false,
      workoutExerciseIdForDelete: "",
    };
  },
  mounted() {
    this.getWorkoutExerciseList();
  },
  methods: {
    gotoWorkoutExercise() {
      this.$router.push({ name: "WorkoutExerciseCreate" });
    },
    gotoWorkoutExerciseEdit(workoutExerciseId) {
      this.$router.push({
        name: "WorkoutExerciseEdit",
        params: { id: workoutExerciseId },
      });
    },
    showDeletePopup(workoutExerciseId) {
      this.workoutExerciseIdForDelete = workoutExerciseId;
      this.showPopup = true;
    },
    deleteWorkoutExercise() {
      APIService.delete(
        `/workoutlist/${this.workoutExerciseIdForDelete}`
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getWorkoutExerciseList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getWorkoutExerciseList() {      
      this.exercises = [];
      APIService.get(
        `/workoutlist/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.exercises = WorkoutExerciseFactory.createFromJsonArray(
              response.data.data
            );
          }
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getWorkoutExerciseList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "workoutname") {
        sortField = "workoutname";
      } else if (sortData.sortField === "target_muscle") {
        sortField = "target_muscle";
      } else if (sortData.sortField === "difficulty") {
        sortField = "difficulties";
      } else if (sortData.sortField === "created_by") {
        sortField = "createdBy";
      } else if (sortData.sortField === "created_at") {
        sortField = "createdAt";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getWorkoutExerciseList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getWorkoutExerciseList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>