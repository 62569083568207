import { createWebHistory, createRouter } from "vue-router";
import Store from '../store';
import MainLayout from "@/views/main-layout.vue";
import Login from "@/views/login.vue";
import ForgotPassword from "@/views/forgot-password.vue";
import MemberAccount from "@/views/MemberAccount/member-list.vue";
import MemberAccountForm from "@/views/MemberAccount/member-form.vue";
import ExercisePlan from "@/views/ExercisePlan/exercise-list.vue";
import ExercisePlanForm from "@/views/ExercisePlan/exercise-form.vue";
import WorkoutExercise from "@/views/WorkoutExercise/workout-exercise-list.vue";
import WorkoutExerciseForm from "@/views/WorkoutExercise/workout-exercise-form.vue";
import TutorialVideo from "@/views/TutorialVideo/tutorial-video-list.vue";
import TutorialVideoForm from "@/views/TutorialVideo/tutorial-video-form.vue";
import Insights from "@/views/Insights/insights-list.vue";
import InsightForm from "@/views/Insights/insight-form.vue";
import WorkoutExperiance from "@/views/WorkoutExperiance/workout-experiance-list.vue";
import WorkoutExperianceForm from "@/views/WorkoutExperiance/workout-experiance-form.vue";
import TargetMuscle from "@/views/TargetMuscle/target-muscle-list.vue";
import TargetMuscleForm from "@/views/TargetMuscle/target-muscle-form.vue";
import IdealBodyShape from "@/views/IdealBodyShape/ideal-body-shape-list.vue";
import IdealBodyShapeForm from "@/views/IdealBodyShape/ideal-body-shape-form.vue";
import Difficulty from "@/views/Difficulty/difficulty-list.vue";
import DifficultyForm from "@/views/Difficulty/difficulty-form.vue";
import PrivacyPolicy from "@/views/PrivacyPolicyPage/privacy-policy.vue"
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue"

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login"
        }
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
            title: "Forgot password"
        }
    },
    {
        component: MainLayout,
        children: [
            {
                path: "/member-account",
                name: "MemberAccount",
                component: MemberAccount,
                meta: {
                    requiresAuth: true,
                    module: 'MemberAccount',
                    title: "Member account"
                },
            },
            {
                path: "/member-account/create",
                name: "MemberAccountCreate",
                component: MemberAccountForm,
                meta: {
                    requiresAuth: true,
                    module: 'MemberAccount',
                    title: "Add member account"
                },
            },
            {
                path: "/member-account/edit/:id",
                name: "MemberAccountEdit",
                component: MemberAccountForm,
                meta: {
                    requiresAuth: true,
                    module: 'MemberAccount',
                    title: "Edit member account"
                },
            },
            {
                path: "/exercise-plan",
                name: "ExercisePlan",
                component: ExercisePlan,
                meta: {
                    requiresAuth: true,
                    module: 'ExercisePlan',
                    title: "Exercise plan"
                },
            },
            {
                path: "/exercise-plan/create",
                name: "ExercisePlanCreate",
                component: ExercisePlanForm,
                meta: {
                    requiresAuth: true,
                    module: 'ExercisePlan',
                    title: "Add exercise plan"
                },
            },
            {
                path: "/exercise-plan/edit/:id",
                name: "ExercisePlanEdit",
                component: ExercisePlanForm,
                meta: {
                    requiresAuth: true,
                    module: 'ExercisePlan',
                    title: "Edit exercise plan"
                },
            },
            {
                path: "/workout-exercise",
                name: "WorkoutExercise",
                component: WorkoutExercise,
                meta: {
                    requiresAuth: true,
                    module: 'WorkoutExercise',
                    title: "Workout exercise"
                },
            },
            {
                path: "/workout-exercise/create",
                name: "WorkoutExerciseCreate",
                component: WorkoutExerciseForm,
                meta: {
                    requiresAuth: true,
                    module: 'WorkoutExercise',
                    title: "Add workout exercise"
                },
            },
            {
                path: "/workout-exercise/edit/:id",
                name: "WorkoutExerciseEdit",
                component: WorkoutExerciseForm,
                meta: {
                    requiresAuth: true,
                    module: 'WorkoutExercise',
                    title: "Edit workout exercise"
                },
            },
            {
                path: "/tutorial-video",
                name: "TutorialVideo",
                component: TutorialVideo,
                meta: {
                    requiresAuth: true,
                    module: 'TutorialVideo',
                    title: "Tutorial video"
                },
            },
            {
                path: "/tutorial-video/create",
                name: "TutorialVideoCreate",
                component: TutorialVideoForm,
                meta: {
                    requiresAuth: true,
                    module: 'TutorialVideo',
                    title: "Add tutorial video"
                },
            },
            {
                path: "/tutorial-video/edit/:id",
                name: "TutorialVideoEdit",
                component: TutorialVideoForm,
                meta: {
                    requiresAuth: true,
                    module: 'TutorialVideo',
                    title: "Edit tutorial video"
                },
            },
            {
                path: "/insights",
                name: "Insights",
                component: Insights,
                meta: {
                    requiresAuth: true,
                    module: 'Insights',
                    title: "Insights"
                },
            },
            {
                path: "/insight/create",
                name: "InsightCreate",
                component: InsightForm,
                meta: {
                    requiresAuth: true,
                    module: 'Insights',
                    title: "Add insights"
                },
            },
            {
                path: "/insight/edit/:id",
                name: "InsightEdit",
                component: InsightForm,
                meta: {
                    requiresAuth: true,
                    module: 'Insights',
                    title: "Edit insights"
                },
            },
            {
                path: "/workout-experiance",
                name: "WorkoutExperiance",
                component: WorkoutExperiance,
                meta: {
                    requiresAuth: true,
                    module: 'WorkoutExperiance',
                    title: "Workout experiance"
                },
            },
            {
                path: "/workout-experiance/create",
                name: "WorkoutExperianceCreate",
                component: WorkoutExperianceForm,
                meta: {
                    requiresAuth: true,
                    module: 'WorkoutExperiance',
                    title: "Add workout experiance"
                },
            },
            {
                path: "/workout-experiance/edit/:id",
                name: "WorkoutExperianceEdit",
                component: WorkoutExperianceForm,
                meta: {
                    requiresAuth: true,
                    module: 'WorkoutExperiance',
                    title: "Edit workout experiance"
                },
            },
            {
                path: "/target-muscle",
                name: "TargetMuscle",
                component: TargetMuscle,
                meta: {
                    requiresAuth: true,
                    module: 'TargetMuscle',
                    title: "Target muscle"
                },
            },
            {
                path: "/target-muscle/create",
                name: "TargetMuscleCreate",
                component: TargetMuscleForm,
                meta: {
                    requiresAuth: true,
                    module: 'TargetMuscle',
                    title: "Add target muscle"
                },
            },
            {
                path: "/target-muscle/edit/:id",
                name: "TargetMuscleEdit",
                component: TargetMuscleForm,
                meta: {
                    requiresAuth: true,
                    module: 'TargetMuscle',
                    title: "Edit target muscle"
                },
            },
            {
                path: "/ideal-body-shape",
                name: "IdealBodyShape",
                component: IdealBodyShape,
                meta: {
                    requiresAuth: true,
                    module: 'IdealBodyShape',
                    title: "Ideal body shape"
                },
            },
            {
                path: "/ideal-body-shape/create",
                name: "IdealBodyShapeCreate",
                component: IdealBodyShapeForm,
                meta: {
                    requiresAuth: true,
                    module: 'IdealBodyShape',
                    title: "Add ideal body shape"
                },
            },
            {
                path: "/ideal-body-shape/edit/:id",
                name: "IdealBodyShapeEdit",
                component: IdealBodyShapeForm,
                meta: {
                    requiresAuth: true,
                    module: 'IdealBodyShape',
                    title: "Edit ideal body shape"
                },
            },
            {
                path: "/difficulty",
                name: "Difficulty",
                component: Difficulty,
                meta: {
                    requiresAuth: true,
                    module: 'Difficulty',
                    title: "Difficulty"
                },
            },
            {
                path: "/difficulty/create",
                name: "DifficultyCreate",
                component: DifficultyForm,
                meta: {
                    requiresAuth: true,
                    module: 'Difficulty',
                    title: "Add difficulty"
                },
            },
            {
                path: "/difficulty/edit/:id",
                name: "DifficultyEdit",
                component: DifficultyForm,
                meta: {
                    requiresAuth: true,
                    module: 'Difficulty',
                    title: "Edit difficulty"
                },
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
                meta: {
                    requiresAuth: true,
                    module: 'PrivacyPolicy',
                    title: "Privacy policy"
                },
            },
            {
                path: "/terms-conditions",
                name: "TermsAndConditions",
                component: TermsAndConditions,
                meta: {
                    requiresAuth: true,
                    module: 'TermsAndConditions',
                    title: "Terms and conditions"
                },
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const title = 'Faith & Health';
    if (Store.state.timeout == null || (Store.state.timeout != null && Store.state.timeout > new Date())) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setUserTimeout');
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else if (to.name === undefined) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else {
            Store.commit('setUserTimeout');
            if (to.name == "Login" && Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', 'MemberAccount');
                next("/member-account");
                return
            } else {
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }            
        }
    } else {        
        Store.dispatch("logout");
        next("/login");
        return
    }
})

export default router;
