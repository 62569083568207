<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 faith-form-title">{{ title }}</div>
      <div class="faith-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="faith-form-sub-title">Exercise Plan Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.plan_name"
                :validation="v$.vmodel.plan_name"
                sectionClass="field mt-1"
                id="plan_name"
                label="Plan name"
                maxlength="50"
              />
              <DropDownRequire
                v-model="vmodel.workout_experiance"
                :validation="v$.vmodel.workout_experiance"
                sectionClass="field mt-1"
                id="workout_experiance"
                label="Experience for workout"
                :options="workoutExpList"
                optionLabel="name"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.target_muscle"
                :validation="v$.vmodel.target_muscle"
                sectionClass="field mt-1"
                id="target_muscle"
                label="Target muscle"
                :options="targetMuscleList"
                optionLabel="name"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.ideal_body_shape"
                :validation="v$.vmodel.ideal_body_shape"
                sectionClass="field mt-1"
                id="ideal_body_shape"
                label="Ideal body shape"
                :options="bodyShapeList"
                optionLabel="name"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.difficulty"
                :validation="v$.vmodel.difficulty"
                sectionClass="field mt-1"
                id="difficulty"
                label="Difficulty"
                :options="difficultyList"
                optionLabel="name"
                optionValue="id"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="faith-form-button"
                  v-if="!isEditMode"
                  @click="createExercisePlan"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="faith-form-button"
                  v-else
                  @click="createExercisePlan"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>
  
<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Add Exercise Plan",
      isEditMode: false,
      vmodel: {
        plan_name: "",
        workout_experiance: "",
        target_muscle: "",
        ideal_body_shape: "",
        difficulty: "",
      },
      submitted: false,
      workoutExpList: [],
      targetMuscleList: [],
      bodyShapeList: [],
      difficultyList: [],
    };
  },
  validations() {
    return {
      vmodel: {
        plan_name: { required, max: maxLength(50) },
        workout_experiance: { required },
        target_muscle: { required },
        ideal_body_shape: { required },
        difficulty: { required },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Exercise Plan";
      this.isEditMode = true;
      this.getExerciseById();
    }
    this.getWorkoutExepriance();
    this.getTargetMuscle();
    this.getIdealBodyShape();
    this.getDifficultyList();
  },
  methods: {
    async createExercisePlan() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          planName: this.vmodel.plan_name,
          expe_Workout: this.vmodel.workout_experiance,
          targetMuscle: this.vmodel.target_muscle,
          idealBodyshape: this.vmodel.ideal_body_shape,
          difficulty: this.vmodel.difficulty,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/exercise_plan", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ExercisePlan" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/exercise_plan/", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ExercisePlan" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },

    async getExerciseById() {
      APIService.getById("/exercise_plan", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.plan_name = response.data.data.planName;
            this.vmodel.workout_experiance = response.data.data.expe_Workout;
            this.vmodel.target_muscle = response.data.data.targetMuscle;
            this.vmodel.ideal_body_shape = response.data.data.idealBodyshape;
            this.vmodel.difficulty = response.data.data.difficulty;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "ExercisePlan" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    getWorkoutExepriance() {
      APIService.get("/workout_exp/name/asc")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.workoutExpList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    getTargetMuscle() {
      APIService.get("/target_muscle/name/asc")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.targetMuscleList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    getIdealBodyShape() {
      APIService.get("/body_shape/name/asc")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.bodyShapeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    getDifficultyList() {
      APIService.get("/difficulty/name/asc")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.difficultyList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>