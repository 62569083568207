<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 faith-form-title">{{ title }}</div>
      <div class="faith-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="faith-form-sub-title">
                Experience For Workout Detail
              </div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.name"
                :validation="v$.vmodel.name"
                sectionClass="field mt-1"
                id="name"
                label="Experience for workout"
                maxlength="50"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="faith-form-button"
                  v-if="!isEditMode"
                  @click="createWorkoutExperiance"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="faith-form-button"
                  v-else
                  @click="createWorkoutExperiance"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text absolute" />
</template>
      
<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Add Experience For Workout",
      isEditMode: false,
      vmodel: {
        name: "",
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        name: { required, max: maxLength(50) },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Experience For Workout";
      this.isEditMode = true;
      this.getWorkoutExperianceById();
    }
  },
  methods: {
    async createWorkoutExperiance() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          name: this.vmodel.name,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/workout_exp", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "WorkoutExperiance" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/workout_exp/", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "WorkoutExperiance" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getWorkoutExperianceById() {
      APIService.getById("/workout_exp", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.name = response.data.data.name;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "WorkoutExperiance" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>