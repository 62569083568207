import ExercisePlan from '@/models/exercise-plan';

export default class ExercisePlanFactory {
    static createFromJson(json) {
        return new ExercisePlan(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(ExercisePlanFactory.createFromJson(item))
        })
        
        return jsonData
    }
}