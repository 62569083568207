import videoImage from "@/assets/images/img.png";
import DateFormat from "@/libs/DateFormat"

export default class TutorialVideo {
    constructor(data = {}) {
        return {
            id: data.id || '',
            video: videoImage,
            tutorialname: data.tutorialname || '',
            target_muscle: data.target_muscle || '',
            difficulty: data.difficulties || '',
            created_by: data.createdBy || '',
            created_at: DateFormat.formatDate(data.createdAt) || '',
        }
    }
}
