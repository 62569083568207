import moment from 'moment';

export default {
formatDate(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
  }
}