import DateFormat from "@/libs/DateFormat"

export default class ExercisePlan {
    constructor(data = {}) {
        return {
            id: data.id || '',
            planName: data.planName || '',
            expe_Workout: data.expe_Workout || '',
            created_by: data.createdBy || '',
            created_at: DateFormat.formatDate(data.createdAt) || '',
        }
    }
}
