<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-container">
    <div class="login-form">
      <div class="login-title">
        <img src="../assets/images/logo.svg" alt="" class="logo" />
      </div>
      <div class="form-container">
        <div class="form-title">Login to your account</div>
        <div class="form-inputs">
          <div class="field mt-1">
            <label for="email" class="form-label">Email</label>
            <InputText
              id="email"
              type="email"
              v-model="vmodel.emailAddress"
              :class="{
                'p-invalid': v$.vmodel.emailAddress.$error,
              }"
              aria-describedby="email-help"
              maxlength="50"
            />
            <span
              v-if="
                v$.vmodel.emailAddress.$error &&
                v$.vmodel.emailAddress.required.$invalid
              "
              id="email-help"
              class="p-error text-left mt-2"
            >
              {{
                v$.vmodel.emailAddress.required.$message.replace(
                  "Value",
                  "Email"
                )
              }}
            </span>
            <span
              v-else-if="
                v$.vmodel.emailAddress.$error &&
                v$.vmodel.emailAddress.email.$invalid
              "
              id="email-help"
              class="p-error text-left mt-2"
            >
              {{ v$.vmodel.emailAddress.email.$message }}
            </span>
            <span
              v-else-if="
                v$.vmodel.emailAddress.$error &&
                v$.vmodel.emailAddress.max.$invalid
              "
              id="email-help"
              class="p-error text-left mt-2"
            >
              {{ v$.vmodel.emailAddress.max.$message }}
            </span>
          </div>
          <div class="field mt-1">
            <div class="flex-row">
              <label for="password" class="form-label float-left"
                >Password</label
              >
              <label
                for="password"
                class="form-label forgot-password cursor-pointer"
                @click="gotoForgotPassword"
                >Forgot Password?</label
              >
            </div>
            <span class="p-input-icon-right">
              <i
                :class="
                  showPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'password-show-icon cursor-pointer'
                "
                @click="showPassword = !showPassword"
              />
              <InputText
                id="password"
                :type="passwordInputType"
                class="w-full"
                v-model="vmodel.password"
                :class="{
                  'p-invalid': v$.vmodel.password.$error,
                }"
                aria-describedby="password-help"
                minlength="8"
                maxlength="20"
              />
            </span>
            <span
              v-if="
                v$.vmodel.password.$error &&
                v$.vmodel.password.required.$invalid
              "
              id="password-help"
              class="p-error text-left mt-2"
            >
              {{
                v$.vmodel.password.required.$message.replace(
                  "Value",
                  "Password"
                )
              }}
            </span>
            <span
              v-else-if="
                v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
              "
              id="password-help"
              class="p-error text-left mt-2"
            >
              {{ v$.vmodel.password.min.$message }}
            </span>
            <span
              v-else-if="
                v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
              "
              id="password-help"
              class="p-error text-left mt-2"
            >
              {{ v$.vmodel.password.max.$message }}
            </span>
          </div>
          <div class="field mt-6 mb-2">
            <PrimeButton
              label="Login"
              class="prime-button"
              :disabled="submitted"
              @click="login"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      Faith & Health Rehabilitation Service Company
    </div>
    <PrimeToast />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, email, minLength, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showPassword: false,
      vmodel: {
        emailAddress: null,
        password: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        emailAddress: { required, email, max: maxLength(50) },
        password: { required, min: minLength(8), max: maxLength(20) },
      },
    };
  },
  computed: {
    passwordInputType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
  },
  methods: {
    gotoForgotPassword() {
      this.$router.push({ name: "ForgotPassword" });
    },
    async login() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          emailId: this.vmodel.emailAddress,
          password: this.vmodel.password,
        };
        this.submitted = true;
        APIService.post("/admin/login", payload)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.$store.commit("setUserInfo", response.data.data);
              this.$store.commit("setUserToken", response.data.token);
              this.$store.dispatch("login");
              this.$router.push({ name: "MemberAccount" });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/login.css";
</style>