<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 faith-form-title">{{ title }}</div>
      <div class="faith-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="faith-form-sub-title">Workout Exercise Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.exercise_name"
                :validation="v$.vmodel.exercise_name"
                sectionClass="field mt-1"
                id="exercise_name"
                label="Exercise Name"
                maxlength="50"
              />
              <TextAreaRequire
                v-model="vmodel.exercise_description"
                :validation="v$.vmodel.exercise_description"
                sectionClass="field mt-1"
                id="exercise_description"
                label="Exercise description"
                maxlength="500"
              />
              <DropDownRequire
                v-model="vmodel.target_muscle"
                :validation="v$.vmodel.target_muscle"
                sectionClass="field mt-1"
                id="target_muscle"
                label="Target muscle"
                :options="targetMuscleList"
                optionLabel="name"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.difficulty"
                :validation="v$.vmodel.difficulty"
                sectionClass="field mt-1"
                id="difficulty"
                label="Difficulty"
                :options="difficultyList"
                optionLabel="name"
                optionValue="id"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="faith-form-button"
                  v-if="!isEditMode"
                  @click="createWorkoutExercise"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="faith-form-button"
                  v-else
                  @click="createWorkoutExercise"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>
    
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import APIService from "@/services/api.service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Add Workout Exercise",
      isEditMode: false,
      vmodel: {
        exercise_name: "",
        target_muscle: "",
        difficulty: "",
        exercise_description: "",
      },
      targetMuscleList: [],
      difficultyList: [],
    };
  },
  validations() {
    return {
      vmodel: {
        exercise_name: { required, max: maxLength(50) },
        target_muscle: { required },
        difficulty: { required },
        exercise_description: { required },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Workout Exercise";
      this.isEditMode = true;
      this.getWorkoutExerciseById();
    }
    this.getTargetMuscle();
    this.getDifficultyList();
  },
  methods: {
    async createWorkoutExercise() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          workoutname: this.vmodel.exercise_name,
          target_muscle: this.vmodel.target_muscle,
          difficulty: this.vmodel.difficulty,
          workoutdescription: this.vmodel.exercise_description,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/workoutlist", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "WorkoutExercise" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/workoutlist/", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "WorkoutExercise" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getWorkoutExerciseById() {
      APIService.getById("/workoutlist", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.exercise_name = response.data.data.workoutname;
            this.vmodel.target_muscle = response.data.data.target_muscle;
            this.vmodel.difficulty = response.data.data.difficulty;
            this.vmodel.exercise_description =
              response.data.data.workoutdescription;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "WorkoutExercise" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getTargetMuscle() {
      APIService.get("/target_muscle/name/asc")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.targetMuscleList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getDifficultyList() {
      APIService.get("/difficulty/name/asc")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.difficultyList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>